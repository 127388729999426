const Settings = {
    env: "stg",
    webUrl: "http://localhost:3000",
    apiUrl: "http://localhost:8000",
    mediaHost: "https://dvkvars7ubgms.cloudfront.net",
    s3Host: "https://s3-ap-northeast-1.amazonaws.com/stg.hecafe.com.tw",
    debug: true,
    facebookAppId: "1276459929127208",
    googleClientId: "115128487422-bs5m8q90u3g4ata8lb9ujorusgpqhefj.apps.googleusercontent.com",
    googleAnalyticsId: "UA-127064537-1",
    userType: "aws",
    slsApi: {
      host: "http://localhost:3000",
    },
};

module.exports = Settings;

