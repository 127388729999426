import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Title = styled.p`
  color: #3e3a39;
  font-size: 46px;
  font-weight: bold;
  line-height: 60px;
  letter-spacing: 6px;

  ${props => props.css}
`;

const Subtitle = styled.div`
  font-family: "GenSekiGothic-B";
  display: flex;
  align-items: center;
  flex-direction: row;
  color: #3e3a39;
  font-size: 34px;
  font-weight: bold;
  line-height: 48px;
  letter-spacing: 1px;

  ${props => props.css}
`;

const Content = styled.p`
  color: #3e3a39;
  font-size: 34px;
  line-height: 48px;
  letter-spacing: 2px;
  white-space: pre-wrap;
  text-align: justify;
  font-family: "GenSekiGothic-R";
  ${props => props.css}
`;

const PaddingLine = styled.div`
  margin-left: 10px;
  background-color: #3e3a39;
  height: 3px;
  flex: 1;
  ${props => props.css}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${props => props.css}
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.css}
`;

const Padding = styled.div`
  flex: 1;
`;

const BackBtn = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 10px;

  & > p {
    font-family: "GenSekiGothic-R";
    color: #3e3a39;
    text-align: center;
    font-size: 30px;
    letter-spacing: 4pt;
    margin-left: 16px;
  }
`;

const HomeBtn = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 10px;

  & > p {
    font-family: "GenSekiGothic-R";
    color: #3e3a39;
    text-align: center;
    font-size: 30px;
    letter-spacing: 4pt;
    margin-left: 16px;
  }
`;

const BtnsWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  flex-grow: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${props => props.css}

  & > .row {
    display: flex;
    flex-direction: row;
  }
`;

const BottomDeco = styled.div`
  margin-top: 60px;
  height: 50px;
  border-radius: 0px 0px 30px 30px;
  background-color: #f0f0f0;
`;

export {
  Padding,
  BackBtn,
  HomeBtn,
  BtnsWrapper,
  BottomDeco,
  Row,
  Col,
  Subtitle,
  Content,
  Title,
  PaddingLine
};
